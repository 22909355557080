<script setup lang="ts">
  import { useUserStore } from '@/stores/user'
  import { useProjectBackupStore } from '@/stores/project-backups'
  import fetchInterceptor from '@/interceptors/fetch'
  import { IntercomDes } from '@/helpers/intercomDes'
  import { MixpanelDes } from '@/helpers/mixpanelDes'
  import BugSnagDes from '@/helpers/bugSnagDes'

  const user = useUserStore()
  if (user.user && !user.isClientAccount()) {
    user.getExtraSettings()
    user.getPublicConfig()
  }

  const backups = useProjectBackupStore()
  backups.openIndexedDb()

  fetchInterceptor(window)

  IntercomDes.setupIntercom(IntercomDes.skipIntercom)
  MixpanelDes.setupMixpanel(IntercomDes.skipIntercom)
  BugSnagDes.instance.setBugSnag(user.user)
</script>

<template>
  <RouterView :key="$route.fullPath" />
</template>
