import { defineStore } from 'pinia'
import { ref } from 'vue'
import { http } from '@/helpers/http'
import type { TTagHint, TNiche } from '@/types/tags'
import type { TDropdownOption } from '@/components/form/DesDropdown.vue'

export const useTagsStore = defineStore('tags', () => {
  const hints = ref<TTagHint[]>([])
  const niches = ref<TNiche[]>([])
  const nicheDropdownOptions = ref<TDropdownOption[]>([])

  let fetched: boolean = false
  let nichesFetched: boolean = false

  async function fetchTags() {
    if (fetched) {
      return
    }
    try {
      const res = await http.post<TTagHint[]>('/tags/hints')
      if (!res) throw new Error('Failed to fetch themes')
      hints.value = res
      fetched = true
    } catch {
      hints.value = []
    }
  }

  async function fetchNiches() {
    if (nichesFetched) {
      return
    }
    try {
      niches.value = await http.get<TNiche[]>('/tags/templates')
      nicheDropdownOptions.value = niches.value.filter(niche => niche.tag_images.length > 0).map((niche) => ({
        name: niche.name,
        label: niche.name,
      }))
      nichesFetched = true
    } catch {
      throw new Error('Failed to fetch niches')
    }
  }

  return { hints, niches, nicheDropdownOptions, fetchTags, fetchNiches }
})
