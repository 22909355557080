<script setup lang="ts">
  import { ref } from 'vue'
  import { useRouter } from 'vue-router'

  type TMenuItem = {
    selected?: boolean
    danger?: boolean
    icon?: string | string[]
    image?: string
    description?: string
    tip?: string
    more?: boolean
    external?: boolean
    to?: string
    multiline?: number
  }

  const props = defineProps<TMenuItem>()
  const clampClass = ref<string>(`line-clamp-${props.multiline}`)
  const router = useRouter()
</script>

<template>
  <a
    @click="to ? router.push(to) : undefined"
    class="px-2 py-1 cursor-pointer leading-2 rounded-lg flex hover:bg-white-gray select-none w-full"
    :class="[description ? 'items-start gap-4' : 'items-center gap-2', danger ? 'text-red-60' : 'text-blue-10']"
    :menu-toggle-ignore="more || undefined"
  >
    <des-icon v-if="icon" :icon="icon" class="h-icon w-icon" :class="{ 'mt-0.5': description }" />
    <img v-else-if="image" :src="image" class="h-8 self-center" alt="" />
    <div class="flex flex-col grow shrink overflow-hidden">
      <div
        class="grow shrink text-ellipsis gap-2"
        :class="{
          'font-medium text-sm': description,
          'overflow-hidden whitespace-nowrap flex': !multiline,
          [clampClass]: multiline,
        }"
      >
        <slot></slot>
      </div>
      <div class="grow text-xs text-black-40 shrink leading-4" v-if="description">
        {{ description }}
      </div>
    </div>
    <des-icon v-if="selected" icon="fa-light fa-check" class="pointer-events-none h-icon w-icon text-blue-50" />
    <des-icon v-if="tip" icon="fa-light fa-circle-question" class="h-icon w-icon text-black-60" v-des-tooltip="tip" />
    <des-icon v-if="more" icon="fa-light fa-chevron-right" class="pointer-events-none h-icon w-icon self-center" />
    <des-icon v-if="external" icon="fa-light fa-arrow-up-right" class="pointer-events-none h-icon w-icon" />
  </a>
</template>
