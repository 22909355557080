<script setup lang="ts">
  import { ref, onMounted } from 'vue'
  import { MaskInput } from 'maska'

  type TInputProps = {
    label?: string
    disabled?: boolean
    readonly?: boolean
    placeholder?: string
    error?: string
    icon?: string
    inline?: boolean
    maska?: string
    type?: string
    name?: string
    autocomplete?: string
  }

  type TInputPropsModelString = TInputProps & {
    modelValue: string
    number?: false
  }
  type TInputPropsModelNumber = TInputProps & { modelValue: number; number: true }

  const props = defineProps<TInputPropsModelString | TInputPropsModelNumber>()
  const input = ref<HTMLInputElement | null>(null)

  defineExpose({
    input,
  })

  onMounted(() => {
    if (props?.maska && input?.value) {
      new MaskInput(input.value, {
        mask: props.maska,
      })
    }
  })
</script>

<template>
  <label class="flex-col gap-1 select-none" :class="inline ? 'inline-flex' : 'flex w-full'">
    <div v-if="label" class="text-black-10 text-sm leading-tight">
      {{ label }}
    </div>
    <div class="relative flex">
      <des-icon
        v-if="icon"
        :icon="icon"
        :spin="icon?.includes('fa-spin') ?? undefined"
        class="absolute left-4 top-1/2 -translate-y-1/2 w-icon h-icon text-blue-10"
      />
      <input
        :class="{
          'cursor-not-allowed': disabled,
          'border-red-50': error,
          'border-black-90 hover:border-black-60 focus:border-black-60': !error,
          'pl-10': icon,
          'rounded w-full': !inline,
          'rounded-lg w-48': inline,
        }"
        ref="input"
        :value="modelValue"
        :disabled="disabled"
        :readonly="readonly"
        :placeholder="placeholder"
        :type="type"
        :name="name"
        :autocomplete="autocomplete"
        @input="!disabled && !readonly && $emit('update:modelValue', input?.value)"
        @blur="$emit('blur')"
        class="relative h-10 p-3 border text-black-10 text-sm leading-none placeholder-black-60 outline-none bg-transparent transition-colors"
      />
      <slot></slot>
    </div>
    <div class="text-red-50 text-xs leading-4" v-if="error">
      {{ error }}
    </div>
  </label>
</template>
