import { useEventListener } from '@vueuse/core'
import { ref, type Directive } from 'vue'

export const DesHorizontalScroll: Directive = {
  mounted(el: HTMLElement) {
    const element = ref<HTMLElement>(el)
    let ticking = false
    let delta = 0

    function onScroll(event: WheelEvent) {
      event.preventDefault()
      delta = event.deltaY

      !ticking && requestAnimationFrame(update)
      ticking = true
    }

    function update() {
      ticking = false

      el.scrollLeft += delta
    }

    useEventListener(element, 'wheel', onScroll)
  },
}
