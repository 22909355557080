<script setup lang="ts">
  import { ref, onMounted, computed } from 'vue'
  import { useUserStore } from '@/stores/user'
  import { useCreditsStore } from '@/stores/credits'
  import { useAIStore } from '@/stores/openai'
  import { getIcon, getLabel } from '@/helpers/product'
  import DesButtonLink from '@/components/button/DesButtonLink.vue'
  import DesIconGold from '@/components/DesIconGold.vue'

  const user = useUserStore()
  const credits = useCreditsStore()

  const ttsCreditsLeft = ref<number>(0)
  const maxTtsCredits = ref<number>(0)
  const audioMinutesLeft = ref<number>(0)
  const maxAudioMinutes = ref<number>(0)
  const aiStore = useAIStore()

  onMounted(async () => {
    const [creditsInfo] = await Promise.all([credits.getCreditsInfo(), aiStore.getCredits()])

    maxTtsCredits.value = creditsInfo?.tts_more?.max_credits ? creditsInfo.tts_more.max_credits : 0
    ttsCreditsLeft.value = creditsInfo?.tts_seconds_left ? creditsInfo.tts_seconds_left : 0

    maxAudioMinutes.value = creditsInfo?.more?.max_seconds ? Math.floor(creditsInfo.more.max_seconds / 60) : 0
    audioMinutesLeft.value = creditsInfo?.seconds_left ? Math.floor(creditsInfo.seconds_left / 60) : 0
  })

  function percentage(max: number, used: number): number {
    return max > 0 ? Math.floor((used / max) * 100) : 0
  }

  // const wordgeniePercent = computed<number>(() => {
  //   const cred = aiStore.status.credits !== null ? aiStore.status.credits : 0
  //   return percentage(aiStore.status.totalCredits, cred)
  // })

  const ttsPercent = computed<number>(() => {
    return percentage(maxTtsCredits.value, ttsCreditsLeft.value)
  })

  const audioMinutesPercent = computed<number>(() => {
    return percentage(maxAudioMinutes.value, audioMinutesLeft.value)
  })
</script>

<template>
  <div class="p-3">
    <div class="p-4 bg-white-gray rounded-lg flex-col justify-start items-stretch gap-3 flex">
      <div class="text-blue-10 font-medium leading-none flex gap-2 items-center">
        <DesIconGold :icon="getIcon(user.user.account_type)" />
        {{ getLabel(user.user.account_type) }}
      </div>

      <!--
      <div v-if="aiStore.status.totalCredits">
        <div class="text-blue-10 text-sm">Wordgenie credits</div>
        <div class="h-1.5 flex bg-black-90 rounded items-stretch">
          <div class="bg-blue-10 rounded" :style="{ width: wordgeniePercent + '%' }"></div>
        </div>
        <div class="text-black-40 text-xs">{{ aiStore.status.credits }} / {{ aiStore.status.totalCredits }}</div>
      </div>
      -->

      <div v-if="maxTtsCredits">
        <div class="text-blue-10 text-sm">Audiobook credits</div>
        <div class="h-1.5 flex bg-black-90 rounded items-stretch">
          <div class="bg-blue-10 rounded" :style="{ width: ttsPercent + '%' }"></div>
        </div>
        <div class="text-black-40 text-xs">{{ ttsCreditsLeft }} / {{ maxTtsCredits }}</div>
      </div>

      <div v-if="maxAudioMinutes">
        <div class="text-blue-10 text-sm">Monthly minutes</div>
        <div class="h-1.5 flex bg-black-90 rounded items-stretch">
          <div class="bg-blue-10 rounded" :style="{ width: audioMinutesPercent + '%' }"></div>
        </div>
        <div class="text-black-40 text-xs">{{ audioMinutesLeft }} / {{ maxAudioMinutes }}</div>
      </div>
      <div>
        <DesButtonLink to="/profile#upgrade">Need more credits?</DesButtonLink>
      </div>
    </div>
  </div>
</template>
