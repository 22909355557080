type TJSONValue = string | number | null | boolean | { [x: string]: TJSONValue } | Array<TJSONValue>

type TURLSearchParams = { [key: string]: string | boolean | number }
export const http = {
  get,
  post,
  delete: del,
}

function get(url: string, params: TURLSearchParams, returnAsText: true): Promise<string>
function get<T>(url: string, params?: TURLSearchParams, returnAsText?: false): Promise<T>
function get<T>(url: string, params?: TURLSearchParams, returnAsText?: boolean) {
  return httpRequest<T>(appendGetParamsIfPresent(url, params), 'GET', null, returnAsText)
}

function post(url: string, params: TJSONValue, returnAsText: true): Promise<string>
function post<T>(url: string, params?: TJSONValue, returnAsText?: false): Promise<T>
function post<T>(url: string, params?: TJSONValue, returnAsText?: boolean) {
  return httpRequest<T>(url, 'POST', params, returnAsText)
}

function del(url: string, returnAsText: true): Promise<string>
function del<T>(url: string, returnAsText?: false): Promise<T>
function del<T>(url: string, returnAsText?: boolean) {
  return httpRequest<T>(url, 'DELETE', null, returnAsText)
}

async function httpRequest<T>(
  url: string,
  method: string,
  params?: TJSONValue,
  returnAsText?: boolean,
): Promise<T | string> {
  const response = await fetch(url, {
    method: method.toUpperCase(),
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: params ? JSON.stringify(params) : null,
  })

  if (!response.ok) {
    const text = await response.text()
    throw new HTTPError(response.status, text)
  }

  const contentType = response?.headers?.get('content-type')
  if (!contentType) {
    throw new Error('No content type in the response!')
  }

  if (returnAsText !== true) {
    return (await response.json()) as T
  } else {
    return await response.text()
  }
}

function appendGetParamsIfPresent(urlString: string, params?: TURLSearchParams): string {
  if (!params) {
    return urlString
  }

  const url = new URL(window.location.origin + urlString)
  Object.entries(params).forEach((keyValPair: [string, string | boolean | number]) =>
    url.searchParams.set(keyValPair[0], keyValPair[1].toString()),
  )

  return url.toString()
}

export class HTTPError extends Error {
  constructor(public status: number, public response: string) {
    super(`HTTP Error: ${status} ${response}`);
  }
}
