import { defineStore } from 'pinia'
import { http } from '@/helpers/http'
import type { TCreditsInfo } from '@/types/credits'

export const useCreditsStore = defineStore('credits', () => {

  async function getCreditsInfo (): Promise<TCreditsInfo | null> {
    const creditsInfo: TCreditsInfo | null = await http.get<TCreditsInfo>('/user_admin/user_audio_info')
    return creditsInfo
  }

  return { getCreditsInfo }
})
