import { defineStore } from 'pinia'
import { http } from '@/helpers/http'
import { pipe } from '@/helpers/pipe'
import { ref } from 'vue'
import { track } from '@/helpers/mixpanelDes'
import { useDraftsStore } from '@/stores/drafts'
import { useLoaderStore } from '@/stores/loader'
import { useRouter } from 'vue-router'
import { useSnackbarStore } from '@/stores/snackbar'
import {
  type TAIBaseResponse,
  type TAIChapterResponse,
  type TAIContent,
  type TAICreditsResponse,
  type TAIGenerateResponse,
  type TAIImage,
  type TAIImageGenResponse,
  type TAIKeywordsResponse,
  type TAIModifyMode,
  type TAIModifyResponse,
  type TAINichesResponse,
  type TAIOutline,
  type TAIOutlineResponse,
  type TAIStatus,
  type TAITagsResponse,
  type TAIThemesResponse,
  type TAITitlesResponse,
} from '@/types/openai'
import type { TDraft } from '@/types/drafts'
import type { TWordGenieOperation } from '@/types/editor'

export const useAIStore = defineStore('openai', () => {
  const snack = useSnackbarStore()
  const loader = useLoaderStore()
  const drafts = useDraftsStore()
  const router = useRouter()
  const lastWordgenieOperation = ref<null | TWordGenieOperation | null>(null)

  const status = ref<TAIStatus>({
    credits: null,
    tokenUsed: 0,
    error: null,
    cancel: false,
    draftId: null,
    generating: false,
    modifyTextMode: null,
    usedPromptText: '',
    totalCredits: 0,
  })

  const languages = [
    { name: 'English', label: '🇬🇧 English' },
    { name: 'Dutch', label: '🇳🇱 Dutch' },
    { name: 'French', label: '🇫🇷 French' },
    { name: 'German', label: '🇩🇪 German' },
    { name: 'Hungarian', label: '🇭🇺 Hungarian' },
    { name: 'Italian', label: '🇮🇹 Italian' },
    { name: 'Polish', label: '🇵🇱 Polish' },
    { name: 'Portuguese', label: '🇵🇹 Portuguese' },
    { name: 'Romanian', label: '🇷🇴 Romanian' },
    { name: 'Spanish', label: '🇪🇸 Spanish' },
  ]

  const tones = [
    { name: 'Informative', label: '👮 Informative' },
    { name: 'Encouraging', label: '💪🏼 Encouraging' },
    { name: 'Constructive', label: '🔧 Constructive' },
    { name: 'Professional', label: '💼 Professional' },
    { name: 'Enthusiastic', label: '🤩 Enthusiastic' },
    { name: 'Humorous', label: '🤡 Humorous' },
    { name: 'Inspirational', label: '💫 Inspirational' },
    { name: 'Educational', label: '👩🏻‍🏫 Educational' },
    { name: 'Sincere', label: '😐️ Sincere' },
    { name: 'Playful', label: '😜 Playful' },
  ]

  const charactersLimit = 5000

  function resetStatus() {
    status.value.tokenUsed = 0
    status.value.draftId = null
    status.value.generating = false
    status.value.error = null
    status.value.cancel = false
    status.value.modifyTextMode = null
    status.value.usedPromptText = ''
  }

  async function getCredits() {
    try {
      const data = await http.post<TAICreditsResponse>('/ai/credits')

      updateStatus(data)
      if (data?.status !== 'success') {
        return
      }

      const credits = data.result
      const extraCredits = credits.extra_ai_credits === 0 ? 0 : credits.extra_ai_credits - credits.used_extra_ai_credits
      const baseCredits = credits.max_ai_credits - credits.used_ai_credits

      status.value.credits = baseCredits + extraCredits
      status.value.totalCredits = credits.max_ai_credits + credits.extra_ai_credits
    } catch (e) {
      console.log('AI getCredits error:', e)
    }
  }

  async function modifyText(textToModify: string, mode: TAIModifyMode): Promise<string | null> {
    if (textToModify.length > charactersLimit) {
      snack.add('Selected text is too long. Please select less than 5000 characters.')
      return null
    }

    const removeQuotes = (s: string) => (s.startsWith('"') && s.endsWith('"') ? s.slice(1, -1) : s)
    const removeDot = (init: string) => (s: string) => (!init.endsWith('.') && s.endsWith('.') ? s.slice(0, -1) : s)

    try {
      const res = await http.post<TAIModifyResponse>('/modifyText', { textToModify, mode })
      status.value.credits = res.creditsLeft ?? 0
      status.value.modifyTextMode = mode
      status.value.usedPromptText = res.usedPromptText
      return pipe(removeQuotes, removeDot(textToModify))(res.result) || null
    } catch (e) {
      console.log('AI modifyText error:', e)
      return null
    }
  }

  async function generateText(prompt: string, refresh: boolean) {
    let res

    loader.show('Thinking...')
    try {
      res = await http.post<TAIGenerateResponse>('/ai/inline', { prompt, refresh })
    } catch (e) {
      console.log('AI generateText error:', e)
      res = null
    }
    loader.hide()

    if (!res) return null

    if (res.status !== 'success') {
      snack.add(res.message)
      return null
    }

    status.value.tokenUsed += res.totalTokenUsed ?? 0
    status.value.credits = res.creditsLeft ?? 0
    track('generated-ai-prompt', {
      phrase: prompt,
      //uuid: uuid,
      creditsUsed: res.totalTokenUsed,
      creditsLeft: res.creditsLeft,
      refresh,
    })
    return res.result.content
  }

  async function getNiches(niche: string, language: string) {
    let data

    loader.show('Thinking...')
    try {
      data = await http.post<TAINichesResponse>('/ai/subNiches', {
        niche,
        language,
      })
      if (data.status === 'success') {
        track('generated-ai-niches', { token_used: data.totalTokenUsed })
      } else {
        track('generated-ai-niches-failure', { msg: data.status })
      }
    } catch (e: any) {
      track('generated-ai-niches-failure', { msg: e.message })
      console.log('AI getNiches error:', e)
      data = null
    }
    loader.hide()

    updateStatus(data)
    return data?.status === 'success' ? [niche, ...data.result] : []
  }

  async function getTitles(niches: string[], audience: string, language: string) {
    let data

    loader.show('Thinking...')
    try {
      data = await http.post<TAITitlesResponse>('/ai/titles', { niches, audience, language })
      if (data.status === 'success') {
        track('generated-ai-title', { token_used: data.totalTokenUsed })
      } else {
        track('generated-ai-title-failure', { msg: data.status })
      }
    } catch (e: any) {
      track('generated-ai-title-failure', { msg: e.message })
      console.log('AI getTitles error:', e)
      data = null
    }
    loader.hide()

    updateStatus(data)
    return data?.status === 'success' ? data.result : []
  }

  async function getThemes(title: string) {
    let data

    loader.show('Retrieving suggested themes...')
    try {
      data = await http.post<TAIThemesResponse>('/ai/themes', { title })
    } catch (e: any) {
      console.log('AI getThemes error:', e)
      data = null
    }
    loader.hide()
    return data?.status === 'success' ? data.result : []
  }

  async function generateMultipleImage(text: string, aspects: string[]): Promise<Awaited<TAIImage>[]> {
    const images = await Promise.all(
      aspects.map((aspect) => {
        return generateImage(text, aspect)
      }),
    )
    //filters out null and shuts up the TS type check
    return images.filter(Boolean) as TAIImage[]
  }

  async function generateImage(text: string, aspect: string): Promise<TAIImage|null> {
    let data

    try {
      data = await http.post<TAIImageGenResponse>('/ai/generate_image', { text, aspect })
    } catch (e: any) {
      console.log('AI generateImage error:', e)
      data = null
    }
    return data?.status === 'success' ? data?.image : null
  }

  async function getTagFromTitle(title: string): Promise<string> {
    loader.show('Thinking...')
    let data
    try {
      data = await http.post<TAITagsResponse>('/ai/tagFromTitle', {
        title,
      })
    } catch (e) {
      loader.hide()
      track('generated-ai-tag-from-title-failure', {
        msg: typeof e === 'string' ? e : 'HTTP Error',
      })
      console.log('getTagFromTitle', e)
      return ''
    }
    loader.hide()
    return data.result[0]
  }


  async function getKeywords(text: string): Promise<string> {
    loader.show('Thinking...')
    let data
    try {
      data = await http.post<TAIKeywordsResponse>('/ai/keywords', {
        text,
      })
      /*
      if (data.status === 'success') {
        track('generated-ai-outline', { token_used: data.totalTokenUsed })
      } else {
        track('generated-ai-outline-failure', { msg: data.status })
      }
        */
    } catch (e: any) {
      //track('generated-ai-outline-failure', { msg: e.message })
      console.log('AI getOutline error:', e)
      data = null
      return ''
    }
    loader.hide()

    if (data?.status === 'success') {
      return data.result.query
    } else {
      return ''
    }
  }

  async function getOutline(
    niches: string[],
    audience: string,
    title: string,
    language: string,
    tone: string,
    tags: number[],
  ) {
    let data

    loader.show('Thinking...')
    try {
      data = await http.post<TAIOutlineResponse>('/ai/outline', {
        niches,
        audience,
        title,
        language,
        tone,
      })
      if (data.status === 'success') {
        track('generated-ai-outline', { token_used: data.totalTokenUsed })
      } else {
        track('generated-ai-outline-failure', { msg: data.status })
      }
    } catch (e: any) {
      track('generated-ai-outline-failure', { msg: e.message })
      console.log('AI getOutline error:', e)
      data = null
    }
    loader.hide()

    updateStatus(data)

    if (data?.status === 'success') {
      saveContentAsDraft(title, data.result, niches, audience, language, tone, tags)
      return data.result
    } else {
      return []
    }
  }

  async function getChapter(
    niches: string[],
    audience: string,
    title: string,
    chapterTitle: string,
    subChapterTitle: string,
    language: string,
    tone: string,
    prompt?: string,
  ) {
    let data
    try {
      data = await http.post<TAIChapterResponse>('/ai/subChapterGen', {
        niches,
        audience,
        title,
        chapterTitle,
        subChapterTitle,
        language,
        tone,
        ...(prompt ? { prompt } : {}),
      })
      if (data.status === 'success') {
        track('generated-ai-content-chapter', { token_used: data.totalTokenUsed })
      } else {
        track('generated-ai-content-chapter-failure', { msg: data.status })
      }
    } catch (e: any) {
      track('generated-ai-content-chapter-failure', { msg: e.message })
      console.log('AI getChapter error:', e)
      data = null
    }

    updateStatus(data)

    return data?.status === 'success'
      ? `<p>${data.result.content.trim().replace(/\n\n/g, '</p><p>').replace(/\n/g, '<br>')}</p>`
      : ''
  }

  async function getChapterByObject({
    niches,
    audience,
    title,
    chapterTitle,
    subChapterTitle,
    language,
    tone,
    prompt,
  }: {
    niches: string[]
    audience: string
    title: string
    chapterTitle: string
    subChapterTitle: string
    language: string
    tone: string
    prompt?: string
  }) {
    return getChapter(niches, audience, title, chapterTitle, subChapterTitle, language, tone, prompt)
  }

  function fallbackP(title: string, subtitle?: string, prompt?: string) {
    const escapeHTML = (t: string) => {
      const e = document.createElement('div')
      e.textContent = t
      return e.innerHTML
    }

    return `<p
      data-wordgenie-subtitle="${escapeHTML(subtitle ?? title)}"
      data-wordgenie-title="${escapeHTML(title)}"
      ${prompt ? `data-wordgenie-prompt="${escapeHTML(prompt)}"` : ''}></p>`
  }

  async function saveContentAsDraft(
    title: string,
    content: TAIContent[] | TAIOutline[],
    niches: string[],
    audience: string,
    language: string,
    tone: string,
    tags: number[],
    openDoc = false,
  ) {
    const html = content.reduce(
      (prevH, group) => `${prevH}
      <h2>${group.title}</h2>
      ${!group?.children?.length ? ('content' in group ? group.content : fallbackP(group.title, undefined, group.prompt)) : ''}
      ${group?.children?.reduce(
        (prevP, child) => `${prevP}
        <h3>${child.title}</h3>
        ${'content' in child ? child.content : fallbackP(group.title, child.title, child.prompt)}
      `,
        '',
      )}
    `,
      '',
    )
    const source = JSON.stringify({ title, audience, niches, language, tone })

    return drafts
      .save(status.value.draftId, title, html, '', { type: 'from-wordgenie' }, source, tags)
      .then((res: number) => {
        status.value.draftId = res
        openDoc && router.push('/docs/ebook/' + status.value.draftId)
      })
  }

  async function getFullContent(
    niches: string[],
    audience: string,
    title: string,
    content: TAIContent[],
    language: string,
    tone: string,
    tags: number[],
  ) {
    status.value.generating = true
    loader.showCustom(() => import('@/components/creator/DesCreatorLoader.vue'))

    const updateStates = (done: boolean = false) => {
      saveContentAsDraft(title, content, niches, audience, language, tone, tags).then(() => {
        if (status.value.draftId) {
          drafts.get(status.value.draftId).finally(() => {
            if (status.value.cancel || done) {
              status.value.generating = false
              loader.hide()
            }
          })
        }
      })

      return status.value.cancel
    }

    for (const h2 of content) {
      if (h2.children?.length) {
        for (const h3 of h2.children) {
          const chapter = await getChapter(niches, audience, title, h2.title, h3.title, language, tone, h3.prompt)
          h3.content = chapter
          if (updateStates()) return
        }
      } else {
        const chapter = await getChapter(niches, audience, title, h2.title, h2.title, language, tone, h2.prompt)
        h2.content = chapter
        if (updateStates()) return
      }
    }

    updateStates(true)
  }

  function updateStatus(data: TAIBaseResponse<any> | null) {
    if (!data) {
      status.value.error = 'The server did not respond, please try again in a moment'
    } else if (data.status === 'error') {
      status.value.error = 'There was a problem generating the content, please try again in a moment'
    } else if (data.status === 'moderation_error') {
      status.value.error = data.message
    } else if (data.status === 'success') {
      status.value.credits = data.creditsLeft ?? 0
      status.value.tokenUsed += data.totalTokenUsed ?? 0
      status.value.error = null
    }
  }

  function isUnfinished(draft: Partial<TDraft> | null): boolean {
    if (!draft) return false

    try {
      const emptyParagraphsRegex =
        /<p\s+data-wordgenie-(sub)?title="([^"]+)"\s+data-wordgenie-(sub)?title="([^"]+)"\s*><\/p>/gi
      const isWordgenieDraft = draft.import_type?.includes('from-wordgenie') ?? false
      const importData = JSON.parse(draft.import_source ?? '{}')
      const unnecessaryData = ['title', 'audience', 'niches', 'language', 'tone'].every(
        (key) => importData[key].length > 0,
      )
      const hasUnfinishedChapters = draft.content?.match(emptyParagraphsRegex) !== null

      return isWordgenieDraft && unnecessaryData && hasUnfinishedChapters
    } catch {
      return false
    }
  }

  return {
    languages,
    status,
    tones,
    lastWordgenieOperation,
    resetStatus,
    generateText,
    getCredits,
    getFullContent,
    getKeywords,
    getTagFromTitle,
    getOutline,
    getNiches,
    getTitles,
    getThemes,
    getChapter,
    getChapterByObject,
    modifyText,
    saveContentAsDraft,
    isUnfinished,
    generateImage,
    generateMultipleImage,
  }
})
