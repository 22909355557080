import {
  type TAccountType,
  type TStorageAccountType,
  type TStorageUser,
  type TUser,
  type TUserExtraSettingLabels,
  type TUserExtraSettings,
  type TBillingInfo,
  type TProductInfo,
  type TSubscriptionInfo,
  type TUserExtraSetting,
  type TPublicConfig,
} from '@/types/user'
import TimerUtils from '@/helpers/timerUtils'
import { IntercomDes } from '@/helpers/intercomDes'
import {MixpanelDes, track} from '@/helpers/mixpanelDes'
import { Validators } from '@/helpers/validators'
import { defineStore } from 'pinia'
import { http } from '@/helpers/http'
import { Products } from '@/helpers/product'
import mobileCheck from '@/helpers/mobileCheck'
import { ref } from 'vue'

import { useLoaderStore } from '@/stores/loader'
import { usePopupStore } from '@/stores/popup'
import { useSnackbarStore } from '@/stores/snackbar'
import { useFlipbooksStore } from '@/stores/flipbooks'
import { useStorage } from '@vueuse/core'
import type { TClient } from '@/types/agency'
import BugSnagDes from '@/helpers/bugSnagDes'

function isNumeric(n: any) {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

function getUser(): TUser {
  return useStorage('user', {} as TStorageUser).value.value
}

function logout() {
  const storage = useStorage('user', {} as TStorageUser)
  storage.value = null
  const accountTypeStorage = useStorage('accountType', {} as TStorageAccountType)
  accountTypeStorage.value = null
  if (!window.location.href.includes('/dashboard/login')) {
    window.location.href = '/dashboard/login'
  }
}

function getAccountType(): TAccountType {
  return useStorage('accountType', {} as TStorageAccountType).value.value
}

export const useUserStore = defineStore('user', () => {
  const user = ref<TUser>(getUser())
  const companyUsers = ref<TUser[]>()
  const accountType = ref<TAccountType>(getAccountType())
  const extraSettings = ref<TUserExtraSettings[] | undefined>()
  const publicConfig = ref<TPublicConfig>()
  const flipbooks = useFlipbooksStore()

  const userSettingLabels: TUserExtraSettingLabels = {
    FLIPBOOK_LABEL: 1,
    FLIPBOOK_HIDE_SOCIAL_SHARE: 3,
    PAGE_SIZE: 5,
    OPEN_AI_TERMS_ACCEPTED: 10,
    ONBOARDING_COMPLETED: 11,
    NEW_DASHBOARD: 12,
    NEW_DASH_VISIT_COUNT: 13,
    NEW_DASH_FEEDBACK_SENT: 14,
    NEW_DASH_CANCEL_COUNT: 15,
  }

  async function fetchCompanyUsers(): Promise<void> {
    companyUsers.value = await http.get<TUser[]>('/users')
  }

  async function resetPassword(email: string, resetCode: string, password: string): Promise<string> {
    const loader = useLoaderStore()
    const popup = usePopupStore()
    loader.show('Please wait!')
    try {
      const response = await http.post<any>('/users/reset', {
        email,
        resetCode,
        password,
        version: 'v3',
      })
      loader.hide()
      if (response.status === 'error') {
        return response.message
      }
      await popup.alert('Password changed successfully!')
      logout()
      return 'OK'
    } catch (e) {
      loader.hide()
      return 'Error resetting password '
    }
  }

  async function resetPasswordAgencyClient(
    email: string,
    agency: string,
    resetCode: string,
    password: string,
  ): Promise<string> {
    const loader = useLoaderStore()
    const popup = usePopupStore()
    loader.show('Please wait!')
    try {
      const response = await http.post<any>('/clients/reset', {
        email,
        resetCode,
        password,
        agency,
        version: 'v3',
      })
      loader.hide()
      if (response?.status === 'error') {
        return response.error
      }
      await popup.alert('Your password has been set, now you can login')
      logout()
      return 'OK'
    } catch (e) {
      loader.hide()
      console.log(e)
      return 'Error resetting password '
    }
  }

  async function sendResetEmail(email: string, agencySlug: string): Promise<string> {
    const loader = useLoaderStore()
    const popup = usePopupStore()
    loader.show('Please wait!')
    const data = {
      email: email,
      agency: agencySlug,
      version: 'v3',
    }

    const emailMaybeSend =
      'If the email you entered is valid then a message will be sent to your email address with instructions on how to reset your password'
    try {
      await http.post(`/${agencySlug ? 'clients' : 'users'}/send_reset_code`, data, true)
      loader.hide()
      await popup.alert(emailMaybeSend)
      window.location.href = '/dashboard/login'
      return 'OK'
    } catch (e: any) {
      const errors = e?.message ?? ''
      //await http.post('/logs/log_reset_password_failed', { stacktrace: errors }, true)
      loader.hide()
      if (errors.indexOf('No user with email:') !== -1) {
        await popup.alert(emailMaybeSend)
        window.location.href = '/dashboard/login'
      } else if (errors.indexOf('https://go.designrr.io/designrr-activating-account') !== -1) {
        return errors
      } else if (errors.indexOf(' is deactivated!') !== -1) {
        return errors
      } else {
        return 'Error processing request'
      }
      return ''
    }
  }

  async function loginClient(email: string, password: string) {
    const loader = useLoaderStore()
    loader.show('Please wait!')
    try {
      const response = await http.post<any>('/clients/login', {
        email,
        password,
        errAs200: true,
      })
      if (response.status === 'error') {
        return response.message
      }
      const client = response?.client
      // const token = response.token
      // const accountType = response.accountType
      await afterClientLogin(client)
      return 'OK'
    } catch (e: any) {
      return e?.message ?? 'Error'
    } finally {
      loader.hide()
    }
  }

  async function login(email: string, password: string) {
    const loader = useLoaderStore()
    loader.show('Please wait!')
    try {
      const response = await http.post<any>('/users/login', {
        email,
        password,
        errAs200: true,
      })
      if (response.status === 'error') {
        return response.message
      }
      const user = response.user
      const accountType = response.accountType
      const skipIntercom = !!response.skip_intercom
      await afterLogin(user, accountType, skipIntercom)
      return 'OK'
    } catch (e: any) {
      return e?.message ?? 'Error'
    } finally {
      loader.hide()
    }
  }

  function redirectAfterLogin(user: TUser): void {
    const questionnaire = user?.settings?.onboarding_questionaire

    if (!user?.settings || !questionnaire || questionnaire == '{}') {
      window.location.href = '/dashboard/questionnaire'
    } else {
      window.location.href = '/dashboard/'
    }
  }

  async function commonAfterLogin() {
    await flipbooks.clearTmpPdfFiles()
  }

  async function afterLogin(user: TUser, accountType: TAccountType, skipIntercom: boolean) {
    await TimerUtils.wait(550)
    await commonAfterLogin()
    const storage = useStorage('user', {} as TStorageUser)
    storage.value.value = user
    storage.value.time = Date.now()
    const accountStorage = useStorage('accountType', {} as TStorageAccountType)
    accountStorage.value.value = accountType
    accountStorage.value.time = Date.now()
    localStorage.setItem('skipIntercom', skipIntercom.toString())
    IntercomDes.setupIntercom(skipIntercom)
    MixpanelDes.setupMixpanel(skipIntercom)
    BugSnagDes.instance.setBugSnag(user)

    if (mobileCheck()) {
      window.location.href = '/#/mobile_support'
      return
    }

    redirectAfterLogin(user)
  }

  async function afterClientLogin(client: TClient) {
    const accountType = { agencyClient: true }
    await TimerUtils.wait(550)

    localStorage.setItem(
      'user',
      JSON.stringify({
        value: client,
        time: new Date(),
      }),
    )

    localStorage.setItem(
      'accountType',
      JSON.stringify({
        value: accountType,
        time: new Date(),
      }),
    )

    localStorage.setItem('skipIntercom', 'true')
    IntercomDes.setupIntercom(true)
    MixpanelDes.setupMixpanel(true)
    BugSnagDes.instance.setBugSnag(client)

    if (mobileCheck()) {
      window.location.href = '/#/mobile_support'
      return
    }

    window.location.href = '/app/#/client-dashboard'
  }

  async function getExtraSettings() {
    try {
      const res = await http.post<TUserExtraSettings[]>('/extra_settings/getAllExtraSettings')
      extraSettings.value = res
      checkOldDashboard()
    } catch (e) {
      throw new Error("Can't get extra settings")
    }
  }

  async function checkOldDashboard() {
    const newDashboard = await getExtraSettingByKey(userSettingLabels.NEW_DASHBOARD);
    if (newDashboard !== '1') {
      window.location.href = '/app/';
    }
  }

  async function getPublicConfig() {
    try {
      const res = await http.get<TPublicConfig>('/extra_settings/public_config')
      publicConfig.value = res
    } catch {
      throw new Error("Can't get public config")
    }
  }

  async function saveExtraSetting(key: number | string, value: string) {
    const snack = useSnackbarStore()
    let data: TUserExtraSetting
    if (isNumeric(key)) {
      data = {
        value,
        setting_key_id: key.toString(),
        setting_key: '',
      }
    } else {
      data = {
        value,
        setting_key: key.toString(),
      }
    }

    const res = await http.post('/extra_settings/saveExtraSetting', data)
    if (!res) {
      snack.add('Error saving setting!')
    } else {
      await getExtraSettings()
    }
  }

  async function ping() {
    await http.get('/ping', {}, true)
  }

  async function getExtraSettingByKey(key: number): Promise<string> {
    if (typeof extraSettings.value === 'undefined') {
      await new Promise((resolve) => {
        const check = (): any => (typeof extraSettings.value !== 'undefined' ? resolve(true) : setTimeout(check, 100))
        check()
      })
      return await getExtraSettingByKey(key)
    } else {
      const found = extraSettings.value?.find((setting) => setting?.keys?.id === key)
      return Promise.resolve(found?.value || '')
    }
  }

  async function editUserInfo(email: string, user: TUser): Promise<void> {
    const popup = usePopupStore()
    if (email === '') {
      await popup.alert('Please enter your email!')
      return
    }
    if (!Validators.validateEmail(email)) {
      await popup.alert('Please check the email, it is not valid!')
      return
    }
    const loader = useLoaderStore()
    const errMsg = 'Error saving the changes! Please try again!'
    try {
      loader.show('Please wait!')
      const res: string = await http.post(
        '/user_admin/edit_user',
        {
          original_email: email,
          user: user,
        },
        true,
      )
      loader.hide()
      await popup.alert(res)
    } catch (e) {
      loader.hide()
      await popup.alert(errMsg)
      return
    }
    const storage = useStorage('user', {} as TStorageUser)
    storage.value.value = user
    storage.value.time = Date.now()

    if (email !== user.email) {
      logout()
    }
  }

  async function changePassword(oldPassword: string, newPassword: string, confirmPassword: string): Promise<void> {
    const popup = usePopupStore()
    if (newPassword !== confirmPassword) {
      await popup.alert('Passwords do not match!')
      return
    }
    if (!newPassword || newPassword.length < 6) {
      await popup.alert('Password length is too short!')
      return
    }
    if (oldPassword === newPassword) {
      await popup.alert('Old and new password are the same!')
      return
    }
    const loader = useLoaderStore()
    const defaultErrorMsg = 'Error changing the password! Please try again!'
    try {
      loader.show('Please wait!')
      const response = await http.post(
        '/users/change_password',
        {
          old_password: oldPassword,
          new_password: newPassword,
        },
        true,
      )
      loader.hide()
      await popup.alert(response)
    } catch (e: any) {
      loader.hide()
      await popup.alert(e?.message ?? defaultErrorMsg)
      return
    }
  }

  async function cancelSubscription(sub: TSubscriptionInfo) {
    return await http.post<{result:boolean}>('/chatbot/cancel_subscription', {
      payment: sub,
    })
  }
  async function checkMethod(payment: TProductInfo | TSubscriptionInfo) {
    const response = await http.post<{ chatbot?: boolean, reason?: string}>('/chatbot/check_method', {
      payment
    })
    return response
  }

  async function getBillingHistory(): Promise<TBillingInfo> {
    //NOTE uncomment to test no billing info or to skip TV SPI calls when editing profile
    // return Promise.resolve({
    //   subscriptions:[],
    //   products: []
    // })
    const response = await fetch('/customer/purchase_history')
    if (!response.ok) {
      throw new Error(`Failed to fetch purchase history. Status: ${response.status}`)
    }
    return await response.json()
  }

  function getUserFullName(user: TUser | TClient): string {
    return `${user.first_name ? user.first_name : ''} ${user.last_name ? user.last_name : ''}`.trim()
  }

  function getIdStandard(): number[] {
    return [Products.Standard]
  }

  function getIdPro(): number[] {
    return [Products.Pro]
  }

  function getIdPremium(): number[] {
    return [Products.Agency, Products.Premium, Products.PremiumForAgency]
  }

  function getIdBusiness(): number[] {
    return [Products.Business]
  }

  function getIdAgencyPremium(): number[] {
    return [Products.AgencyPremium]
  }

  function isClientAccount(): boolean{
    // eslint-disable-next-line
    return user.value.hasOwnProperty("agency_id");
  }
  function isAgencyAccount(): boolean {
    if (!user.value?.account_type) {
      return false
    }

    return [Products.Agency, Products.AgencyPremium, Products.PremiumForAgency].includes(user.value.account_type)
  }

  async function getUserById(userId: number): Promise<TUser | null> {
    const authorUser: { user: TUser } = await http.get('/get_user_by_id/' + userId)
    return authorUser?.user ?? null
  }

  // TO REMOVE AFTER OLD DASHBOARD EOL - begin
  function oldDashboardAvailable(): boolean {
    return true
  }
  // TO REMOVE AFTER OLD DASHBOARD EOL - end

  async function setNewDashboard(enabled: boolean) {
    return await http.post(
      '/users/new_dashboard',
      {
        enabled,
      },
      true,
    )
  }

  async function goToOldDashboard(eventSource: string = '') {
    const location = eventSource !== '' ? eventSource : 'from-menu'
    await setNewDashboard(false)
    track('switched-dashboard-version', {
      location,
      version: 'to-old',
    })
    window.location.href = '/app/'
  }

  return {
    user,
    companyUsers,
    logout,
    login,
    loginClient,
    sendResetEmail,
    isAgencyAccount,
    isClientAccount,
    resetPassword,
    resetPasswordAgencyClient,
    afterLogin,
    accountType,
    getIdStandard,
    getIdPro,
    getIdPremium,
    getIdBusiness,
    getIdAgencyPremium,
    getUserById,
    extraSettings,
    publicConfig,
    getExtraSettings,
    getPublicConfig,
    saveExtraSetting,
    ping,
    getExtraSettingByKey,
    userSettingLabels,
    editUserInfo,
    changePassword,
    getBillingHistory,
    fetchCompanyUsers,
    getUserFullName,
    setNewDashboard,
    oldDashboardAvailable, // TO REMOVE AFTER OLD DASHBOARD EOL
    goToOldDashboard,
    checkMethod,
    cancelSubscription,
  }
})
