<script setup lang="ts">
  import { ref } from 'vue'
  import { onClickOutside } from '@vueuse/core'
  import { useProjectsMenuStore } from '@/stores/list-item-menu'
  import { usePopupStore } from '@/stores/popup'
  import { useLoaderStore } from '@/stores/loader'
  import { useProjectsStore } from '@/stores/projects'
  import { useModalStore } from '@/stores/modal'
  import { useQRCodeStore } from '@/stores/qr-code'
  import { useSnackbarStore } from '@/stores/snackbar'
  import { useLiveEbookStore } from '@/stores/live-ebook'
  import { useUserStore } from '@/stores/user'
  import { useFlipbooksStore } from '@/stores/flipbooks'
  import { useBrowserStore } from '@/stores/browser'
  import { useFileInfoStore } from '@/stores/file-info'
  import DesMenuOptions from '@/components/menu/DesMenuOptions.vue'
  import DesMenuItem from '@/components/menu/DesMenuItem.vue'
  import DesMenuSeparator from '@/components/menu/DesMenuSeparator.vue'
  import type { TProject, TProjectsSection } from '@/types/projects'
  import type { TPublishMetadata } from '@/types/live-ebook'
  import type { TAccountType } from '@/types/user'
  import type { TFlipbook, TJsonFlipobookData } from '@/types/flipbooks'

  const menu = useProjectsMenuStore()
  const projects = useProjectsStore()
  const popup = usePopupStore()
  const loader = useLoaderStore()
  const modal = useModalStore()
  const snack = useSnackbarStore()
  const qr = useQRCodeStore()
  const liveEbook = useLiveEbookStore()
  const user = useUserStore()
  const flipbook = useFlipbooksStore()
  const browser = useBrowserStore()
  const fileInfo = useFileInfoStore()

  const accountType = ref<TAccountType>(user.accountType)

  const target = ref<HTMLElement | null>(null)
  onClickOutside(target, () => menu.item && menu.close())

  async function embedFlipbook() {
    if (!menu?.item?.id || isNaN(menu.item.id)) {
      return
    }

    loader.show('Getting data...')
    const createFlipbookData: TFlipbook | null = await flipbook.createFlipbook(menu.item.id)
    loader.hide()

    modal.open(() => import('@/components/modal/DesModalFlipbookEmbed.vue'), {
      createFlipbookData,
    })
  }

  async function generateQR() {
    if (!menu?.item?.id || isNaN(menu.item.id)) {
      return
    }

    const projectId = menu.item.id
    loader.show('Getting data...')

    let formats = await qr.attemptToGetFormats(projectId)
    loader.hide()

    if (formats.length) {
      modal.open(() => import('@/components/modal/DesModalQR.vue'), {
        formats,
      })
    }
  }

  async function editLiveEbookOptions() {
    if (!menu?.item?.id) return

    const projectId: number = menu.item.id

    loader.show('Getting data...')
    const publishMetadata: TPublishMetadata | null = await liveEbook.getPublishMetadata(projectId)
    loader.hide()

    modal.open(() => import('@/components/modal/DesModalLiveEbookOptions.vue'), {
      projectId,
      publishMetadata,
    })
  }

  async function duplicateProject() {
    if (!menu.item) return

    const name = await popup.prompt('Duplicate project', {
      initial: `${menu.item.name} copy`,
      content: 'Enter copy name',
      labelConfirm: 'Duplicate',
      align: 'left',
    })

    if (name) {
      const response = await projects.duplicateProject(menu.item, name)

      if (response.status !== 'success') {
        snack.add(response.message)
      }
    }
  }

  async function openNewWindowTab(url: string) {
    let win = window.open(url, '_blank')
    if (!win) {
      const winResult = await popup.confirm('It looks like your browser blocked a popup/new tab for opening.', {
        labelCancel: 'Cancel',
        labelConfirm: 'Open',
      })

      if (winResult !== false) {
        win = window.open(url, '_blank')
        win?.focus()
      }
      return
    }
    win.focus()
  }

  async function regeneratePDF(project: TProject) {
    const result = await projects.regeneratePDF(project)
    if (result.pdf) {
      openNewWindowTab(result.pdf.url)
    }
  }

  async function openPDFInFlipbook() {
    if (!menu?.item?.id) {
      return
    }

    const errorGettingFlipbookUrl: string = 'Error parsing flipbook'
    const errorNoPdf: string = 'You need to export the PDF first'

    loader.show('Getting data...')

    try {
      const createFlipbookData: TFlipbook | null = await flipbook.createFlipbook(menu.item.id)
      const flipbookData: TJsonFlipobookData = createFlipbookData?.flipbook
        ? JSON.parse(createFlipbookData.flipbook)
        : null
      if (!flipbookData?.url?.length) {
        snack.add(errorNoPdf)
        loader.hide()
        return
      }

      const url: string =
        createFlipbookData?.flipbook?.length && createFlipbookData?.status === 'success'
          ? await flipbook.getFlipbookInfo(createFlipbookData)
          : ''
      if (!url.length) {
        snack.add(errorGettingFlipbookUrl)
        loader.hide()
        return
      }

      loader.hide()
      await browser.openNewWindowTab(url)
    } catch (e: any) {
      loader.hide()
      const message = e?.message
      if (message?.includes('Failed to download external file')) {
        snack.add('Error fetching PDF for this project')
      } else if (message?.includes('pdf not created')) {
        snack.add(errorNoPdf)
      } else {
        snack.add(errorGettingFlipbookUrl)
      }
    }
  }

  async function openPDF() {
    if (!menu.item) return
    const pdfs = await projects.getPDFs(menu.item)

    if (pdfs.pdfs.length === 0) {
      const result = await popup.confirm('Your project has no PDF published. Would you like to publish the PDF?', {
        content: 'Projects larger than 60 pages may take a few minutes to produce.',
        labelCancel: 'Cancel',
        labelConfirm: 'Publish the PDF',
      })

      if (result !== false) {
        regeneratePDF(menu.item)
      }
    } else if (pdfs.outdated === true) {
      const result = await popup.confirm(
        'Your project has been updated since the PDF was last published. Would you like to re-publish the PDF?',
        {
          content: 'Projects larger than 60 pages may take a few minutes to produce.',
          labelCancel: 'Cancel',
          labelConfirm: 'Update the PDF',
        },
      )

      if (result !== false) {
        regeneratePDF(menu.item)
      }
    } else {
      openNewWindowTab(pdfs.pdfs[0].url)
    }
  }

  async function deleteProject() {
    if (!menu.item) return

    const result = await popup.confirm('Are you sure you want to delete this project?', {
      content: 'This action is irreversible.',
      labelCancel: 'Cancel',
      labelConfirm: 'Delete',
    })

    if (result !== false) {
      await projects.deleteProject(menu.item)
    }
  }

  async function moveToFolder() {
    await modal.open<number | undefined>(() => import('@/components/modal/DesModalAddToFolder.vue'), {
      project: menu.item,
      section: projects.section,
    })
  }

  async function saveAsTemplate() {
    await modal.open(() => import('@/components/modal/DesModalSaveProjectAsTemplate.vue'), {
      project: menu.item,
    })
  }

  async function versionHistory() {
    await modal.open(() => import('@/components/modal/DesModalVersionHistory.vue'), {
      project: menu.item,
    })
  }

  async function legacyCover() {
    await modal.open(() => import('@/components/modal/DesModalLegacyCovers.vue'), {
      id: menu.item?.id,
    })
  }

  async function fileInformation() {
    if (!menu?.item) {
      return
    }

    const project: TProject = menu.item

    loader.show('Getting data...')
    const [draft, shares, projectFolder, clientProjects, sections] = await Promise.all([
      fileInfo.getProjectDraft(project),
      fileInfo.getShares(project),
      fileInfo.getProjectFolder(menu.item),
      projects.getClientProjects(menu.item.id),
      projects.fetchSections(),
    ])
    loader.hide()

    modal.open(() => import('@/components/modal/DesModalFileInfo.vue'), {
      item: project,
      draft: draft,
      shares: shares,
      clientProjects,
      projectFolder,
    })
  }
</script>

<template>
  <div
    class="absolute z-50"
    :style="{ left: menu.position.left + 'px', top: menu.position.top + 'px' }"
    ref="target"
    @click="menu.close()"
  >
    <DesMenuOptions :open="menu.opened" :left="menu.flip" :right="!menu.flip">
      <DesMenuItem v-if="accountType['3d_covers']" @click="legacyCover()" icon="fa-light fa-cube">Cover & Mockup Creator</DesMenuItem>
      <DesMenuItem @click="openPDF()" icon="fa-light fa-file-pdf">Open PDF</DesMenuItem>
      <DesMenuItem @click="openPDFInFlipbook()" icon="fa-light fa-book-open">Open PDF in flipbook</DesMenuItem>
      <DesMenuItem v-if="accountType.live_ebook_export" @click="editLiveEbookOptions()" icon="fa-light fa-pen">
        Edit live ebook options
      </DesMenuItem>
      <DesMenuItem v-if="accountType.flipbook_publish" @click="embedFlipbook()" icon="fa-light fa-file-code">
        Generate flipbook embed code
      </DesMenuItem>
      <DesMenuItem @click="generateQR()" icon="fa-light fa-qrcode">Generate QR code</DesMenuItem>
      <DesMenuSeparator />
      <DesMenuItem @click="moveToFolder()" icon="fa-light fa-folder-plus">Move</DesMenuItem>
      <DesMenuItem v-if="accountType.create_templates" @click="saveAsTemplate()" icon="fa-light fa-file-image">
        Save as template
      </DesMenuItem>
      <DesMenuItem @click="duplicateProject()" icon="fa-light fa-copy">Duplicate</DesMenuItem>
      <DesMenuItem @click="versionHistory()" icon="fa-light fa-rotate-left">Version history</DesMenuItem>
      <DesMenuItem @click="fileInformation()" icon="fa-light fa-circle-info">Project information</DesMenuItem>
      <DesMenuSeparator />
      <DesMenuItem @click="deleteProject()" icon="fa-light fa-trash-can" danger>Delete</DesMenuItem>
    </DesMenuOptions>
  </div>
</template>
