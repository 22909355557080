import { defineStore } from 'pinia'
import { ref } from 'vue'
import { ReadabilityWithIframe } from '@/helpers/readabilityWithIframe'
import { useDraftsStore } from './drafts'
import { useSnackbarStore } from '@/stores/snackbar'
import { ImportTypes } from '@/helpers/importTypes'
import { DropboxUrlUtil } from '@/helpers/dropboxUrlUtil'
import { http } from '@/helpers/http'
import { useCookies } from '@vueuse/integrations/useCookies'
import type { TDraftImportType } from '@/types/drafts'
import {
  ERemoteImportURLStatus,
  ERemoteImportURLType,
  TRemoteImportURL,
  type TUploadedFileInfo,
} from '@/types/remote-imports'
import ChunkUploaderClient from '@/helpers/chunkedUpload'
import { useRemoteImportStore } from './remote-import'
import { useUserStore } from './user'
import type { ChunkedUploadModelRecordData } from '@/helpers/chunkedUpload'

export const useLocalImportStore = defineStore('local-import', () => {
  const importType = ref<ERemoteImportURLType | null>(null)
  const urls = ref<TRemoteImportURL[]>([])
  const user = useUserStore()
  const snack = useSnackbarStore()
  const defaultUploadLimits = {
    docx_import: 0,
    pptx_import: 0,
    audio_import: 0,
    video_import: 0,
    pdf_to_flipbook: 0,
    pdf_import: 0,
    fallback: 0,
  }
  const uploadResume = ref<ChunkedUploadModelRecordData | null>(null)

  const remoteImport = useRemoteImportStore()
  const acceptedFormats = ref<string>(
    'audio/mp4,.wav,audio/aac,audio/x-aac,audio/wav,video/mp4,video/x-ms-asf,video/quicktime,audio/wav,audio/x-wav,audio/mpeg,audio/mp3,audio/x-hx-aac-adts,audio/vnd.dlna.adts,audio/x-m4a,.m4a',
  )

  const chunkedClient = new ChunkUploaderClient({
    errorCallback: (error: string) => {
      snack.add(error)
      urls.value = []
    },
    progressCallback: (percent: number) => {
      urls.value[0].status = ERemoteImportURLStatus.Uploading
      if (percent > 100) {
        urls.value[0].progress = 100
      } else {
        urls.value[0].progress = percent
      }
    },
    successCallback: (data: { fileLink: string; fileName: string; uploadedFileInfo?: TUploadedFileInfo }) => {
      urls.value[0].status = ERemoteImportURLStatus.Uploaded
      urls.value[0].fileLink = data.fileLink
      if (['audio', 'video'].includes(importType.value ?? '')) {
        urls.value[0].info = data.uploadedFileInfo
        urls.value[0].status = ERemoteImportURLStatus.ReadyToTranscribe
        return
      }
      remoteImport.importFile(true, data.fileName, urls.value[0])
    },
    uploadLimits: user.publicConfig?.upload_limits || defaultUploadLimits,
    importModulesMap: user.publicConfig?.import_module_map || {},
  })

  async function resume(resumeData: ChunkedUploadModelRecordData, file: File) {
    const buffer = await chunkedClient.getChunkFromBlob(file, resumeData.last_complete_chunk).arrayBuffer()
    if (file.size !== resumeData.size || chunkedClient.calcHash(buffer) !== resumeData.last_complete_chunk_checksum) {
      urls.value.pop()
      snack.add('Upload resume error: wrong file selected')
      return
    }
    chunkedClient.resumeFrom(resumeData, file)
    uploadResume.value = null
  }

  function addFiles(files: File[] | null) {
    if (importType.value !== null && files && files.length > 0) {
      files.forEach((f) => importType.value && urls.value.push(new TRemoteImportURL(importType.value, '', f)))
    } else {
      throw new Error(`Import type not initialized`)
    }

    if (!acceptedFormats.value.split(',').includes(files[0].type)) {
      urls.value.pop()
      snack.add('Wrong file type selected')
      return
    }

    if (uploadResume.value) {
      resume(uploadResume.value, files[0])
    } else {
      chunkedClient.startUpload(files[0], importType.value)
    }
  }

  async function init(type: ERemoteImportURLType, accept?: string) {
    if (accept) {
      acceptedFormats.value = accept
    }
    await user.getPublicConfig()
    chunkedClient.uploadLimits = user.publicConfig?.upload_limits || defaultUploadLimits
    chunkedClient.importModulesMap = user.publicConfig?.import_module_map || {}
    importType.value = type
    await chunkedClient.checkQueue(
      importType.value.toLocaleLowerCase(),
      (resume: ChunkedUploadModelRecordData | null) => {
        if (resume && resume.id !== '0') {
          uploadResume.value = resume
        }
      },
    )
  }

  function removeUrlOrFile(index: number) {
    chunkedClient.cancelUpload()
    urls.value.splice(index, 1)
  }

  async function flushQueue() {
    chunkedClient.flushQueue(importType.value?.toLocaleLowerCase() || '')
    uploadResume.value = null
  }

  return {
    urls,
    importType,
    init,
    flushQueue,
    addFiles,
    chunkedClient,
    uploadResume,
    removeUrlOrFile,
  }
})
